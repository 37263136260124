

.carousel .carousel-indicators [data-bs-target] {
  background-color: var(--bg-color);
}

.carousel .carousel-inner .carousel-item {
  height: 70vh;
}
.hero-machine{
  position: relative;
  top: 50px;
  left: 20px;
  height: 500px;
}

/* Arrow */
.carousel-control-prev-icon {
  transform: scale(1);
  border: 7px solid var(--bg-color);
  border-radius: 50%;
  background-color: var(--bg-color);
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='darkorange' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  border: 7px solid var(--bg-color);
  border-radius: 50%;
  background-color: var(--bg-color);
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='darkorange' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

/* hero-left */
.hero-left {
  width: 50%;
}
.hero-left .c-caption-img {
  width: 75%;
  height: 75%;
}

.hero-left .hero-exclusive {
  font-size: 20px;
  color: rgba(40, 64, 125, 1);
}

@media screen and (max-width: 576px) {
  .carousel-control-prev-icon{
    height: 20px;
    width: 20px;
    border: 1px solid var(--bg-color);
    
  }
    .carousel-control-next-icon {
      border: 1px solid var(--bg-color);
      height: 20px;
      width: 20px;
    }
  .carousel .carousel-inner .carousel-item {
    height: 35vh;
  }

  .carousel-item img {
    width: 120%;
  }

  .hero-exclusive:nth-child(1) {
    display: none;
  }
  .hero-exclusive{
    position: relative;
    top: 50px;
  }
  .hero-exclusive h2 {
    font-size: 20px;
  }

  .animate__animated {
    transition-delay: 1s;
  }
  .btn {
    --bs-btn-padding-x: 0 !important;
    --bs-btn-padding-y: 0 !important;
    position: relative;
    bottom: 50px;
  }
    
  .carousel-caption {
    bottom: 0.5rem !important;
  }
    .carousel-item .hero-machine {
      height: 150px !important;
      bottom: 20px;
    }
}

.carousel-item img {
  width: 100%;
}

.carousel-item .hero-machine {
  width: 90%;
  height: 400px;
  margin: auto;
}
