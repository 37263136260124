html {
  scroll-behavior: smooth;
}

/* News */
.news {
  width: 100%;
  background-color: #8b8ead;
}

/* News */

section {
  padding: 50px 0px;
}
a {
  text-decoration: none;
  outline: none;
  transition: all 0.3s;
}


.banner{
  background: url('../public/images//banner/banner1.jpg') no-repeat center center;
  background-size: cover;
  padding-top: 12%;
  padding-bottom: 12%;
  
}
/* Feature */
.feature img{
  border-radius: 50%;
  border: 1px solid rgb(150, 147, 147);
  padding: 10px;
}

/* Carousel */
.carousel-item img {
  height: auto;
}

.carousel-item img {
  height: 500px;
}
.carousel-control-prev-icon {
  width: 40px;
  height: 40px;
  transform: scale(-1, 1);
  background-image: url('../public/images/arrow.png');
}

.carousel-control-next-icon {
  width: 40px;
  height: 40px;
  background-image: url('../public/images/arrow.png');
}

/* About */


.card-img-top{
  width: 300px;
  border: 1px solid black;
  object-fit: cover;margin: 20px !important;

}