.image-heading {
  background-image: url('../../../public/images/banner/banner3.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  height: 30vh;
  line-height: 10vh;
  position: relative;
  z-index: -2;
}
.image-heading .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.image-heading::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  height: 30vh;
  width: 100%;
  z-index: -1;
}
.image-heading h1 {
  font-size: 40px;
  text-transform: capitalize;
}
.image-heading button {
  background: black;
  color: white;
  border-radius:10px ;
  border: none;
  font-weight: 400;
  padding: 2px 5px;
  word-spacing: 5px;
  cursor: pointer !important;
}

.image-heading a,
a label {
  color: #31b675;
  cursor: pointer !important;
  border: none;
}
.image-heading span {
  text-transform: capitalize;
  word-spacing: 30px;
}
