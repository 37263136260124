/* software_main */
.software .main{
  position: relative;
  bottom: 50px;
  margin-top: 50px;
}
 

.software_main {
  text-align: center;
  background-color: #f37c22;
}

/* software-header */
.software-header {
  color: #fff;
  font-size: 24px;
  margin-bottom: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* software_show */
.software_show img {
  padding-bottom: 10px;
}

.software_show h1 {
  font-size: 24px;
  color: #f37c22;
  margin-bottom: 10px;
}

.software_show p {
  font-size: 14px;
  line-height: 20px;
  color: #383838;
}

.feature {
  width: 100%;
  padding: 50px 0;
  margin-bottom: 50px;
  background: #f3f3f3;
}

.feature-heading {
  display: inline-block;
  padding: 8px 50px;
  color: #fff;
  margin-bottom: 30px;
  background: #f37c22;
  font-size: 16px;
  width: auto;
}
