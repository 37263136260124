/* TopBar */
.topbar {
  background-color:var(--bg-color);
  padding: 10px 0px;
  text-align: center;
}

.topbar__phone a {
  font-size: 15px;
  font-weight: 500;
  color:var(--white);
}
.topbar__phone--tel{
  font-size: 16px;

}
.topbar__social a svg {
  font-size: 30px;
  font-weight: 500;
  margin-left: 3px;
  padding-right: 3px;
  margin-right: 20px;
  cursor: pointer;
}



/* Navbar */
.navbar {
  height: 80px;
  background-color: var(--white) !important;
  -webkit-box-shadow: 0px 0px 10px 2px var(--bg-color);
  -moz-box-shadow: 0px 0px 10px 2px var(--bg-color);
  box-shadow: 0px 0px 10px 2px var(--bg-color);
}
.navbar-brand img {
  display: flex;
  width: 150px;
}

.nav-link {
  position: relative;
  color: var(--header-color);
  font-size: 18px;
  font-weight: 500;
}

.nav-link:hover {
  color: var(--bg-color);
}
.nav-link::before {
  content: '';
  position: absolute;
  display: inline;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--bg-color);
  transform: scaleX(0);
  transition: transform 0.5s ease;
}

.nav-link:hover::before {
  transform: scaleX(1);
}
