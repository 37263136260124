
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500&display=swap');

.about{
    font-family: 'Lora', serif;
    color: #58585c;
}

.about__title{
    font-size: 28px;
    margin-bottom: 30px;
    
}
.about__title::after{
    width: 30px;
    height: 3px;
    content: "";
    display: block;
    margin: 0 auto;
    margin-top: 15px;
    background-color: #eebb8d;
    
}

.about__company-desc{
    background-color: #fff;
    padding: 50px 30px;
    font-size: 14px;
    transition: box-shadow 0.7s #808080;
}

.about__company-desc:hover{
    box-shadow: 2px 2px 5px 5px rgb(176, 172, 172);

}
.about__image{
background-color: #333;
}
.about__image img{
    height: 250px;
    width: 100%;
}
.about__culture--desc{
    padding: 10px;
    background-color: #fff;
    transition: box-shadow 0.5s ease;
}
.about__culture--desc img:hover{
    transition: transform 1s ease-in-out;
    transform: scale(1.05);
}
.about__culture--desc:hover{
    box-shadow: 2px 2px 5px 5px #808080;
}


.about__brand--desc{
    margin: auto;
    font-size: 14px;
    text-align: left;
    padding: 10px 30px;
    transition: transform 0.7s ease-in-out;
}
.about__brand--desc:hover{
    box-shadow: 2px 2px 5px 5px #808080;
    transform: scale(1.02);
}

.about__brand--desc-header{
    padding: 10px;
}
.about__brand--desc-header::after{
     width: 30px;
    height: 3px;
    content: "";
    display: block;
    margin-top: 15px;
    background-color: #eebb8d;
}

.about--certificate{
    background-color: #808080;
}

.about__certificate--images img{
    width: 180px;
}