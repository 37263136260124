.top-to-btm {
  position: relative;
}
.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

.icon-style {
  background-color:var(--bg-color);
  border: 2px solid var(--white);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: var(--white);
  cursor: pointer;
  animation: movbtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}
.icon-style:hover {
  animation: none;
  background: var(--white);
  color: var(--bg-color);
  border: 2px solid var(--bg-color);
}

@keyframes movbtn {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(15px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0px);
  }
}
