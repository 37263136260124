
.footer {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color:#595a5c
}
.footer__logo{
  border-radius: 10px;
  padding: 10px;
  transition: 0.7s ease;
}
.footer__logo--path{
  fill:#f07d22
}
.footer__logo:hover{
  transform: scale(1.02);
  box-shadow: 1px 1px 15px 3px rgb(255, 255, 255);
}
.footer__title {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #f07d22;
  text-transform: uppercase
}

.footer__menu--list {
  padding-left: 0;
  list-style: none
}

.footer__menu--list-item {
  font-size: 15px;
  margin-bottom: 5px;
  display: -ms-flexbox;
  display: flex
} 

.footer__menu--list-link {
  color: #f9f9fa;
  text-transform: uppercase;
  transition: .1s ease-in-out

}

.footer__menu--list-link:hover {
  text-decoration: none;
  color: #f07d22;
  transform: scale(1.05);
}

.footer__bottom {
  font-size: .775rem;
  color: #f9f9fa
}

.footer__bottom--link {
  color: #f9f9fa;
}

.footer__bottom--list {
  padding-left: 0;
  list-style: none;
  margin-bottom: .25rem
}

.footer__bottom--item {
  display: inline-block
}

.footer__bottom--item:not(:last-of-type) {
  margin-right: .5rem
}

.footer__bottom--item:not(:last-of-type):after {
  content: "|";
  margin-left: .5rem
}

.footer__social a{
  margin-left: 20px; 
}