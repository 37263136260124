.service-address {
  height: 260px;
  background-color: #2854a5;
  padding: 30px 25px;
  color: white;
}

.get-in-touch ul li {
  margin: 0;
  list-style: none;
  position: relative;
  padding: 13px 0 13px 50px;
  word-wrap: break-word;
}

.get-in-touch-wrapper {
  display: flex;
  justify-content: flex-start;
}

.icon-logo {
  display: flex;
  flex-direction: column;
}
.icon-logo svg,
.contact-desc span {
  margin: 0px 10px 10px 0;
}

.contact-desc {
  display: flex;
  flex-direction: column;
}
