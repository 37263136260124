.whatsapp__img {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 120px;
  right: 25px;
  z-index: 100;
}


