

/* PageHeader */
.page-header {
    background-color: var(--bg-color) !important;
}
.breadcrumb{
    list-style: none;
    margin: 0;
}
a {
    transition: color 2s ease-in-out;
}

.breadcrumb a{
    color: var(--white);
}


.breadcrumb li::after {
    content: "";
    margin-left: 5px;
    margin-right: 5px;
}
.breadcrumb-item+.breadcrumb-item{
    padding-left: 0.5rem;
}
.breadcrumb-item+.breadcrumb-item:before{
    float: left;
    padding-right: 0.5rem;
    color: var(--white);
    content: '/';
}



/* Privacy */
.privacy h3{
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 300;
    line-height: 1.2;
    color: var(--bg-color);
}
.privacy b{
    font-size: 15px;
    font-weight: 600;
    color: var(--bg-color);
}
.privacy a{
    color: var(--alert);
}
.privacy p{
    margin-top: 0;
    margin-bottom: 0.5rem;
    text-indent: 20px;
}