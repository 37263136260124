.contact__desc{
    margin: auto;
}

.mobile,.mail,.address{
    display: flex;
    padding: 5px;
    border-radius: 10px;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid grey;
    margin: 10px;
    font-size: 24px;
    transition: transform 0.7s ease-in-out;
    cursor: pointer;
    color: grey;

}
.mobile:hover,.mail:hover,.address:hover{
    box-shadow: 2px 2px 5px 5px grey;
    transform: scale(1.1);
}

.mobile-no,.mail-add,.address-desc{
    padding-left: 10px;
    color: grey;

}

.img-fluid{
    border-radius: 5px;
    transition: transform 0.5s ease;
}
.img-fluid:hover{
    transform: scale(1.1);
}