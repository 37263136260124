@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500&display=swap');

.title{
    font-family: 'Lora', serif;
    color: #58585c;
    text-align: center;
}

.title::after{
  width: 30px;
    height: 3px;
    content: "";
    display: block;
    margin: 0 auto;
    margin-top: 15px;
    background-color: #eebb8d;
}


.MuiButtonBase-root {
  position: relative;
  bottom: 76px;
  left: 80px;
}


.cookie-banner{
  position: fixed;
    width: 100%;
    height: 60px;
    bottom: 40px;
    z-index: 100;
    background-color: rgb(142, 135, 135);

}